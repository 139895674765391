import { makeAutoObservable } from 'mobx';

class WidgetStore {
  // Every time this updates, the widget will reload.
  // It can take any value but should be different on every update
  widgetReloadKey = '';

  // Indicates if we are still waiting for the feature flag to be finalized.
  widgetRouteSelectorLocked = false;
  widgetDateTimeMergeLocked = false;
  widgetAutoJumpStepsLocked = false;

  // Keeps the final/locked value of the feature flag after the timeout has passed
  widgetRouteSelectorLockedValue;
  widgetDateTimeMergeLockedValue;
  widgetAutoJumpStepsLockedValue;

  constructor() {
    makeAutoObservable(this);
  }

  updateWidgetReloadKey = (customValue) => {
    this.widgetReloadKey = customValue || new Date().getTime();
  };

  updateWidgetRouteSelectorLocked = (value) => {
    this.widgetRouteSelectorLocked = value;
  };
  updateWidgetDateTimeMergeLocked = (value) => {
    this.widgetDateTimeMergeLocked = value;
  };
  updateWidgetAutoJumpStepsLocked = (value) => {
    this.widgetAutoJumpStepsLocked = value;
  };

  updateWidgetRouteSelectorLockedValue = (value) => {
    this.widgetRouteSelectorLockedValue = value;
  };
  updateWidgetDateTimeMergeLockedValue = (value) => {
    this.widgetDateTimeMergeLockedValue = value;
  };
  updateWidgetAutoJumpStepsLockedValue = (value) => {
    this.widgetAutoJumpStepsLockedValue = value;
  };
}

export default new WidgetStore();
