import React from 'react';
import { IonHeader, IonLabel, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../theme';
import PropTypes from 'prop-types';
import SimpleNavbar from '../../components/menus/SimpleNavbar';
import Container from '../../components/layouts/containers/Container';
import { t } from 'i18next';

const TripsHeader = (props) => {
  const { onSelectTab, selectedTab } = props;

  const handleSelect = (e) => {
    const { value } = e.target;
    onSelectTab(value);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {/* Navbar with logo and language picker */}
      <CustomSimpleNavbar showHelpIcon />

      {/* The tabs to select upcoming or completed trips */}
      <TabsContainer>
        <IonHeader
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 2px 0px'
          }}
        >
          <CustomIonToolbar>
            <CustomSegment value={selectedTab}>
              <CustomIonSegmentButton value="upcoming" onClick={handleSelect}>
                <IonLabel>{t('trips.upcoming')}</IonLabel>
              </CustomIonSegmentButton>
              <CustomIonSegmentButton value="completed" onClick={handleSelect}>
                <IonLabel>{t('trips.completed')}</IonLabel>
              </CustomIonSegmentButton>
            </CustomSegment>
          </CustomIonToolbar>
        </IonHeader>
      </TabsContainer>
    </div>
  );
};

TripsHeader.propTypes = {
  onSelectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string
};

const TabsContainer = styled(Container)``;

const CustomIonToolbar = styled(IonToolbar)`
  padding-top: 0 !important;

  @media (min-width: ${theme.breakpoints[0]}px) {
    margin-top: 24px;
  }
`;

const CustomSimpleNavbar = styled(SimpleNavbar)`
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoints[0]}px) {
    max-width: unset;
  }

  @media (min-width: ${theme.breakpoints[1]}px) {
    display: none;
  }
`;

const CustomSegment = styled(IonSegment)`
  margin: 0 auto;
`;

const CustomIonSegmentButton = styled(IonSegmentButton)`
  text-transform: none;
  --color-checked: #00a269;
  width: 100%;
  font-weight: 600;

  &::part(indicator-background) {
    height: 3px;
  }

  ion-label {
    color: ${theme.colors.base.graphite};
  }

  &.segment-button-checked {
    background-color: #fff;
    --indicator-transition: none;

    ion-label {
      color: ${theme.colors.base.graphite};
    }
  }

  @media (min-width: ${theme.breakpoints[0]}px) {
    background-color: #eaebed;

    ion-label {
      color: ${theme.colors.base.graphiteLight};
    }
  }
`;

export default withTranslation()(withRouter(observer(TripsHeader)));
